/** @jsx jsx */
import { graphql } from "gatsby";
import { UserProfile } from "gatsby-theme-loyalty";
import React, { memo } from "react";
import { jsx } from "theme-ui";

import WidgetWrapper from "../../../WidgetWrapper";
import type { WidgetProps, WidgetUnion } from "../../types";

interface Props extends WidgetProps {
  widget: Extract<WidgetUnion, { __typename: "WidgetUserProfile" }>;
}

const UserProfileWidget: React.FC<Props> = ({
  isFirst,
  isNested,
  isLast,
  widget,
  prevWidget,
  nextWidget,
  containerSize,
}) => {
  return (
    <WidgetWrapper
      isFirst={isFirst}
      isLast={isLast}
      isNested={isNested}
      containerSize={containerSize}
      widget={widget}
      prevWidget={prevWidget}
      nextWidget={nextWidget}
    >
      <UserProfile />
    </WidgetWrapper>
  );
};

export const query = graphql`
  fragment WidgetUserProfile on WidgetUserProfile {
    id
    __typename
  }
`;

export default memo(UserProfileWidget);
